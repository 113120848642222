import axios from "axios";
import $store from "../store";
import toLogin from "@libs/login";

import { VUE_APP_API_URL } from "@utils/index";

// const instance = axios.create({
//     baseURL: VUE_APP_API_URL,
//     timeout: 5000,
// });

const instance = axios.create({
    baseURL: "/sinologyApi/v1",
    timeout: 5000,
});

const defaultOpt = { login: true };

function baseRequest(options) {
    const token = $store.state.app.token;
    const headers = options.headers || {};
    headers["Token"] = "Bearer " + token;
    options.headers = headers;
    if (options.login && !token) {
        toLogin();
        return Promise.reject({ msg: "未登录", toLogin: true });
    }
    return instance(options)
        .then((res) => {
            const data = res.data || {};
            if (res.status == 200) {
                if (data.code === 200) {
                    return Promise.resolve(data, res);
                } else {
                    return Promise.reject({ msg: data.message, res, data });
                }
            }
        })
        .catch((error) => {
            if (
                [10030, 10031, 10032, 10033].indexOf(
                    error.response.data.code
                ) !== -1
            ) {
                $store.commit("LOGOUT");
                toLogin();
                return Promise.reject({ msg: "未登录", toLogin: true });
            } else {
                return Promise.reject({ msg: "请求失败", error });
            }
        });
}

/**
 * http 请求基础类
 * 参考文档 https://www.kancloud.cn/yunye/axios/234845
 *
 */
const request = ["post", "put", "patch"].reduce((request, method) => {
    /**
     *
     * @param url string 接口地址
     * @param data object get参数
     * @param options object axios 配置项
     * @returns {AxiosPromise}
     */
    request[method] = (url, data = {}, options = {}) => {
        return baseRequest(
            Object.assign({ url, data, method }, defaultOpt, options)
        );
    };
    return request;
}, {});

["get", "delete", "head"].forEach((method) => {
    /**
     *
     * @param url string 接口地址
     * @param params object get参数
     * @param options object axios 配置项
     * @returns {AxiosPromise}
     */
    request[method] = (url, params = {}, options = {}) => {
        return baseRequest(
            Object.assign({ url, params, method }, defaultOpt, options)
        );
    };
});

export default request;
